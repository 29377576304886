import React, { useEffect, useState } from 'react'
import { iconAddUser, iconAlignnet, iconGraphic, iconHome, iconMagazine, iconOff, iconSuitCase, iconUser } from '../../utils/routes/assets'
import { deleteCookie, getCookie, setCookie } from '../../utils/functions.js/cookies';
import { Fetch } from '../../utils/functions.js/fetch';
import { APIalignnet } from '../../utils/routes/api';
import { navAddEmployee, navDashboard, navLogin, navScoop, navStats, navVacancies } from '../../utils/routes/navigate';
import { useNavigate } from 'react-router-dom';
import { ButtonNavbar } from '../ui/ButtonNavbar';
import Swal from 'sweetalert2';
import { SwalImage } from '../../utils/functions.js/swal';

export const LayoutNavbar = ({ permissions=1 }) => {
    const navigate = useNavigate();
    const id = getCookie('id_user');
    const [ role, setRole ] = useState(0);
    const [ image, setImage ] = useState('');
    const [ name, setName ] = useState('')

    async function Validate(){
        const data = await Fetch({ url: APIalignnet + 'account/validate/' + id + '/1', });

        if(!data.data || data.data.role <= permissions){ navigate(navLogin) }
        if(data && data.data){
          setCookie({ name: 'role', value: data.data.role });
          setRole(data.data.role);
        }
    }

    async function Info(){
        const data = await Fetch({ url: APIalignnet + 'employee/' + id, });

        if(data && data.data){
            setImage(data.data.image);
            setName(data.data.user);
        }
    }

    function LogOut(){
        Swal.fire({
            title: 'Confirmar',
            text: '¿Estás seguro de que deseas cerrar la sesión?',
            icon: 'question',
            confirmButtonText: 'Sí',
            cancelButtonText: 'No',
            showCancelButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              deleteCookie('id_user');
              deleteCookie('role');
              navigate(navDashboard)
            }
          });
    }
  
    useEffect(() => {
        Validate();
        Info()
    })

    return (
        <div className='navbar'>
            <aside>
                <div>
                    <header>
                        <ButtonNavbar icon={iconAlignnet} text={'Alignnet'} onclick={(e) => SwalImage(iconAlignnet)}/>
                    </header>
                    
                    <div>
                        <ButtonNavbar icon={iconHome} text={'Inicio'} nav={navDashboard}/>
                        {role >= 900 && <ButtonNavbar icon={iconAddUser} text={'Agregar empleado'} nav={navAddEmployee}/>}
                        <ButtonNavbar icon={iconSuitCase} text={'Vacantes'} nav={navVacancies}/>
                        {role >= 900 && <ButtonNavbar icon={iconMagazine} text={'Scoop'} nav={navScoop}/>}
                        <ButtonNavbar icon={iconGraphic} text={'KPI´s'} nav={navStats}/>

                    </div>

                    <footer>
                        <div>
                            <ButtonNavbar icon={image ? image : iconUser} text={'Bienvenido ' + name} onclick={(e) => SwalImage(image ? image : iconUser)}/>
                            <ButtonNavbar icon={iconOff} text={'Cerrar sesión'} onclick={LogOut}/>
                        </div>
                    </footer>

                </div>
            </aside>    
        </div>
    )
}
