import React from 'react'
import { useNavigate } from 'react-router-dom';

export const ButtonNavbar = ({ icon, text, nav, onclick }) => {
    const navigate = useNavigate();

    function HandleClick(e){
        e.preventDefault();
        if(nav){navigate(nav);}
        if(onclick){onclick();}
    }

    return (
        <div className='button-navbar' onClick={(e) => HandleClick(e)}>
            <img src={icon} />
            <p>{text}</p>
        </div>
    )
}
