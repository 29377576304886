import { useState } from 'react';

export const useTable = () => {
    const [ totalPages, setTotalPages ] = useState(0);
    const [ page, setPage ] = useState(0);
    const [ search, setSearch ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ empty, setEmpty ] = useState(false);

    return {
        totalPages, setTotalPages,
        page, setPage,
        search, setSearch,
        loading, setLoading,
        empty, setEmpty,
    };
}