import React from 'react';

export const InputToggle = ({ hook, setHook, placeholder, onchange, height=40, blocked=false }) => {
    const changeValue = () => {
        if(!blocked){
            if(setHook){ setHook(!hook); }
            if(onchange){ onchange(); }
        }
    };

    const toggle = {
        height: height + 'px',
        width: (height * 2) + 'px',
    }

    const span = {
        height: (height - 5) + 'px',
        width: (height - 5) + 'px',
        left: hook ? `calc(100% - ${height}px)` : '5px',
    }

    return (
            <div className={blocked ? 'input-toggle input-toggle-blocked' : 'input-toggle'}>
                {placeholder &&  <p>{placeholder}</p> }

                <div onClick={changeValue} className={hook ? 'toggle toggle-on' : 'toggle toggle-off'}  style={toggle}>
                    <span style={span}></span>
                </div>
            </div>
    );
};