import React from 'react'
import { LayoutNavbar } from '../components/layout/LayoutNavbar'
import { PageEmployee } from '../components/pages/PageEmployee'

export const ViewEmployee = () => {
  return (
    <main>
        <LayoutNavbar/>
        <PageEmployee/>
    </main>
  )
}
