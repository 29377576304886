import React from 'react'

export const LayoutSteps = ({ headers, hook, setHook, children, next, prev, submit }) => {
    const steps = headers.length;
    const progress = { height: 100 * (hook - 1) / (steps - 1) + '%', }

    const bubbles = Array.from({ length: steps }).map((_, i) => {
        const styles = { top: 100 / (steps - 1) * i + '%', }
        return (<span key={i} className={hook >= i+1 ? 'on' : ''} style={styles}><p>{i + 1}</p> </span>);
    });

    function HandleNext(){
        if(hook < steps){
            if(next){next()};
            if(!next){ setHook(hook + 1);};
        }
    }

    function HandlePrev(){
        if (hook > 1) {
            if(prev){prev()};
            if(!prev){ setHook(hook - 1);};
        }
    }


    return (
        <div className='layout-steps'>
            <header>
                <div className='bar'>
                    <div className='progress' style={progress}></div>
                    {bubbles}
                </div>
            </header>

            <div>
                <div>
                    <div>
                        <header>
                            <div>
                                <p>{headers[hook - 1]}</p>
                            </div>
                        </header>

                        {children}
                    </div>
                </div>

                <footer>
                    <div>
                        <button className={hook == 1 ? 'blocked' : ''} onClick={(e) => HandlePrev()}>Anterior</button>
                        { hook != steps && <button onClick={(e) => HandleNext()}>Siguiente</button> }
                        { hook == steps && <button onClick={(e) => submit()} >Enviar</button> }
                    </div>
                </footer>
            </div>
        </div>
    )
}
