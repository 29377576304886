import React, { useEffect, useState } from 'react'
import { iconUser } from '../../utils/routes/assets'
import { Fetch } from '../../utils/functions.js/fetch';
import { APIalignnet } from '../../utils/routes/api';
import { Image } from '../ui/Image';

export const LayoutUserProfile = ({ image, name, hireDate, employeeNumber, isActive, status }) => {
    const [ totalStatuses, setTotalStatuses ] = useState(0);
    const [ statusName, setStatusName ] = useState('');
    const [ preview, setPreview ] = useState(image);

    async function GetStatuses(){
        const data = await Fetch({url: APIalignnet + 'statuses',});
        setTotalStatuses(data.data.length);

        const latestStatus = data.data[data.data.length - 1];
        setStatusName(latestStatus.name);
    };

    useEffect(() => {
        GetStatuses();
    }, [])

    useEffect(() => {
        if (typeof image !== 'object') { setPreview(image); }
    }, [image]);

    return (
        <div className='layout-user-profile'>
            <div>
                <div className='image'>
                    <Image image={preview || iconUser}/>
                </div>
        
                <div className='info'>
                    <div>
                        <div>
                            <p className='employee-number'>{employeeNumber}</p>
                            <p className='name'>{name}</p>
                        </div>
                        <div><p className='hire-date'>{hireDate}</p></div>
                    </div>
                </div>
        
                <div className='data'>
                    <div>
                        <p className='status'>{status}/{totalStatuses} {statusName}</p>
                        <div className='point'><span className={isActive ? 'is-active' : ''}></span></div>
                    </div>
                </div>
            </div>
        </div>
    )
}