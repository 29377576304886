import React, { useEffect, useState } from 'react'
import { iconDoubleLeftRow, iconDoubleRightRow, iconLeftRow, iconRightRow } from '../../utils/routes/assets';
import { LayoutLoader } from './LayoutLoader';


export const LayoutTable = ({ functionChangePage, currentPage, totalPages, children, loading, empty, filters }) => {
    const [ search, setSearch ] = useState('');

    function ChangePage(page, searching=search){
        setSearch(searching);

        //if(page >= 0 && page <= totalPages - 1){
        if(page >= 0 && page <= totalPages){

            functionChangePage({ 
                page: page,
                searching: searching,
            });
        }

    }

    function Searching(e){
        setSearch(e.target.value);
        ChangePage(currentPage, e.target.value);
    }
    
    useEffect(() => {
        ChangePage(0);
    }, [])
  
    return (
        <div className='layout-table'>
            <header>
                <div>
                    <div className="filters">{filters}</div>
                    <input type="text" placeholder='Buscar' value={search} onChange={(e) => Searching(e)}/>
                </div>
            </header>

            {loading ? <span><LayoutLoader/></span> : ''}
            {!loading && !empty && <div>{children}</div>}
            {!loading && empty && <span><h1>No se encontraron registros</h1></span>}

            <footer>
                <div>
                    <span onClick={(e) => ChangePage(0)}><img src={iconDoubleLeftRow}/></span>
                    <span onClick={(e) => ChangePage(currentPage - 1)}><img src={iconLeftRow}/></span>

                            { currentPage + 2 >= totalPages && currentPage - 4 >= 0 ? <span onClick={() => ChangePage(currentPage - 4)}><span><p>{currentPage - 3}</p></span></span> : ''}
                            { currentPage + 1 >= totalPages && currentPage - 3 >= 0 ? <span onClick={() => ChangePage(currentPage - 3)}><span><p>{currentPage - 2}</p></span></span> : ''}
                            { currentPage - 2 >= 0 ? <span onClick={() => ChangePage(currentPage - 2)}><span><p>{currentPage - 1}</p></span></span> : ''}
                            { currentPage - 1 >= 0 ? <span onClick={() => ChangePage(currentPage - 1)}><span><p>{currentPage}</p></span></span> : ''}

                            <span className='current-page'><p>{currentPage + 1}</p></span>

                            { currentPage + 1 < totalPages ? <span onClick={() => ChangePage(currentPage + 1)}><span><p>{currentPage + 2}</p></span></span> : ''}
                            { currentPage + 2 < totalPages ? <span onClick={() => ChangePage(currentPage + 2)}><span><p>{currentPage + 3}</p></span></span> : ''}
                            { currentPage - 1 < 0 &&  currentPage + 3 < totalPages ? <span onClick={() => ChangePage(currentPage + 4)}><span><p>{currentPage + 4}</p></span></span> : ''}
                            { currentPage - 2 < 0 &&  currentPage + 4 < totalPages ? <span onClick={() => ChangePage(currentPage + 5)}><span><p>{currentPage + 5}</p></span></span> : ''}
                    
                    <span onClick={(e) => ChangePage(currentPage + 1)}><img src={iconRightRow}/></span>
                    <span onClick={(e) => ChangePage(totalPages - 1)}><img src={iconDoubleRightRow}/></span>
                </div>
            </footer>
        </div>
    )
  }