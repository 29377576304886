import React, { useEffect, useState } from 'react'
import { LayoutTable } from '../layout/LayoutTable'
import { CardUser } from '../ui/CardUser'
import { Fetch } from '../../utils/functions.js/fetch';
import { APIalignnet } from '../../utils/routes/api';
import { InputToggle } from '../ui/InputToggle';
import { useTable } from '../../utils/hooks/useTable';

export const PageDashboard = () => {
    const table = useTable();
    const [ employees, setEmployees ] = useState([]);
    const [ totalStatuses, setTotalStatuses ] = useState(0);
    const [ filterSMX, setFilterSMX ] = useState(true);
    const [ filterSSC, setFilterSSC ] = useState(true);
    const [ filterActive, setFilterActive ] = useState(true);
    const [ filterInactive, setFilterInactive ] = useState(true);

    async function GetEmployees({ page = 0, searching=''} = {} ){
        table.setLoading(true);
        const limit = 30;
        table.setSearch(searching);
        const offset = page * limit;

        const data = await Fetch({
            url: APIalignnet + 'employees',
            params: {
                "limit": limit,
                "offset": offset,
                "search": searching,
                "smx": filterSMX,
                "ssc": filterSSC,
                "active": filterActive,
                "inactive": filterInactive,

            }
        });

        if(data.data.employees.length == 0){ table.setEmpty(true) }
        if(data.data.employees.length > 0){ table.setEmpty(false) }

        if(data.data.pages > 0){
            if( page >= 0 && page < data.data.pages){
                table.setPage(page);
                table.setTotalPages(data.data.pages);
                setEmployees(data.data.employees);
            }
        }

        if(data.data.pages == 0){
            table.setPage(page);
            setEmployees(data.data.employees);
        }
        table.setLoading(false);
    }

    async function GetStatuses(){
        const data = await Fetch({url: APIalignnet + 'statuses',});
        setTotalStatuses(data.data.length);
    };

    useEffect(() => {
        GetStatuses();
    }, [])

    useEffect(() => {
        GetEmployees({ page: 0, searching: table.search, })
    }, [filterSMX, filterSSC, filterActive, filterInactive])

    const filters = (
        <div>
            <div><p>SMX</p><InputToggle hook={filterSMX} setHook={setFilterSMX} height={25}/></div>
            <div><p>SCC</p><InputToggle hook={filterSSC} setHook={setFilterSSC} height={25}/></div>
            <div><p>Activo</p><InputToggle hook={filterActive} setHook={setFilterActive} height={25}/></div>
            <div><p>Inactivo</p><InputToggle hook={filterInactive} setHook={setFilterInactive} height={25}/></div>
        </div>
    );

    return (
        <div className='page page-dashboard'>
            <LayoutTable functionChangePage={GetEmployees} currentPage={table.page} totalPages={table.totalPages} loading={table.loading} empty={table.empty} filters={filters}>
                <div>
                    {employees.map((employee, index) => {
                        return(
                            <div key={index}>
                                <CardUser
                                    id={employee.id}
                                    name={employee.user}
                                    number={employee.employee_number}
                                    image={employee.image}
                                    department={employee.department}
                                    businessName={employee.business_name}
                                    status={employee.status_position}
                                    statusName={employee.status}
                                    totalStatuses={totalStatuses}
                                    isActive={employee.is_active}
                                />
                            </div>
                    )})}
                </div>
            </LayoutTable>
        </div>
    )
}
