import React from 'react'

export const LayoutSections = ({ headers=[], hook, setHook, children }) => {

    const cursor = {
        width: 100 / headers.length + '%',
        left: 100 / headers.length * hook + '%',
    }

    return (
        <div className='layout-sections'>
            <header>
                <div>
                    {headers.map((header, index) => { return ( <span key={index} className='head' onClick={() => setHook(index)}> {header} </span> ); })}
                    <span className='cursor' style={cursor}><p>{headers[hook]}</p></span>
                </div>
            </header>
        
            <div className='content'>
                {children}
            </div>
        </div>
    )
}
