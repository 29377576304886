import React, { useEffect, useState } from 'react'
import { CardDoughnut } from '../ui/CardDoughnut'
import { APIalignnet } from '../../utils/routes/api';
import { Fetch } from '../../utils/functions.js/fetch';
import { LayoutTable } from '../layout/LayoutTable';

export const PageStats = () => {
    const [ abandonRate, setAbandonRate ] = useState([]);
    const [ averageHiringTime, setAverageHiringTime ] = useState([]);
    const [ hiringRate, setHiringRate ] = useState([]);

    async function GetStats({} = {} ){
        const data = await Fetch({
            url: APIalignnet + 'stats',
        });


        setAbandonRate(data['abandon_rate']);
        setAverageHiringTime(data['average_hiring_time']);
        setHiringRate(data['hiring_rate']);
    } 
    
    useEffect(() => {
        GetStats()
    }, [])

    return (
        <div className='page page-stats'>
            <div className='grid-6'>
    
                <div className='card-stats'>
                    <div>
                        <header><div><h1>Tasa de abandono</h1></div></header>
                        <div><div><p>{abandonRate}</p></div></div>
                    </div>
                </div>
    
                <div className='card-stats'>
                    <div>
                        <header><div><h1>Tiempo promedio de contrataión</h1></div></header>
                        <div><div><p>{averageHiringTime}</p></div></div>
                    </div>
                </div>
    
    
                <div className='card-stats'>
                    <div>
                        <header><div><h1>Tasa de contratación</h1></div></header>
                       <div><div><p>{hiringRate}</p></div></div>
                    </div>
                </div>
    
    
            </div>
        </div>
    )
}
