import React, { useEffect, useState } from 'react'
import { LayoutTable } from '../layout/LayoutTable'
import { useTable } from '../../utils/hooks/useTable';
import { Fetch } from '../../utils/functions.js/fetch';
import { APIalignnet } from '../../utils/routes/api';
import { Image } from '../ui/Image';

export const PageVacancies = () => {
    const table = useTable();
    const [ vacancies, setVacancies ] = useState([]);

    async function GetVacancies({ page = 0, searching=''} = {} ){
        table.setLoading(true);
        const limit = 10;
        table.setSearch(searching);
        const offset = page * limit;

        const data = await Fetch({
            url: APIalignnet + 'vacancies',
            params: {
                "limit": limit,
                "offset": offset,
                "search": searching,
            }
        });

        if(data.data.vacancies.length == 0){ table.setEmpty(true) }
        if(data.data.vacancies.length > 0){ table.setEmpty(false) }

        if(data.data.pages > 0){
            if( page >= 0 && page < data.data.pages){
                table.setPage(page);
                table.setTotalPages(data.data.pages);
                setVacancies(data.data.vacancies);
            }
        }

        if(data.data.pages == 0){
            table.setPage(page);
            setVacancies(data.data.vacancies);
        }
        table.setLoading(false);
    }

    useEffect(() => {
        GetVacancies({ page: 0, searching: table.search, })
    }, [])

    return (
        <div className='page page-vacancies'>
            <LayoutTable functionChangePage={GetVacancies} currentPage={table.page} totalPages={table.totalPages} loading={table.loading} empty={table.empty}>
                <div className='vacancies'>

                <header>
                    <div>
                        <div className='image'></div>
                        <div className='user'>Solicitante</div>
                        <div className='department'>Departamento</div>
                        <div className='date'>Fecha de inicio</div>
                        <div className='date'>Fecha límite</div>
                        <div className='days'>Días restantes</div>
                    </div>
                </header>



                {vacancies.map((vacancie, index) => {
                    const deadlineDate = new Date(vacancie.deadline);
                    const startDate = new Date(vacancie.start);
                    const differenceInMs = deadlineDate.getTime() - startDate.getTime();
                    const days = differenceInMs / (1000 * 60 * 60 * 24);

                    return(
                        <div key={index} className='vacancy'>
                            <div>
                                <div className='image'><div><Image image={vacancie.image}/></div></div>
                                <div className='user'>{vacancie.user}</div>
                                <div className='department'>{vacancie.department}</div>
                                <div className='date'>{vacancie.start}</div>
                                <div className='date'>{vacancie.deadline}</div>
                                <div className='days'>{days}</div>
                            </div>
                        </div>
                    )})}
                </div>
            </LayoutTable>
        </div>
    )
}