import axios from "axios";

export function Fetch({ method = 'get', url, data = {}, params = {}, headers = {} }) {
    const config = {
      method: method,
      url: url,
      headers: headers,
      params: params,
    };
  
    if (['post', 'put', 'patch'].includes(method.toLowerCase())) {
      config.data = data;
    }
  
    return axios(config)
      .then(response => { return response.data; })
      .catch(error => { throw error; })
      .finally(() => { });
}
