import { useEffect, useRef, useState, useLayoutEffect } from "react";

export const ButtonImage = ({ onclick, icon, text, height=30, rotate=90 }) => {
    const [ position, setPosition ] = useState('bottom');
    const tagRef = useRef(null);
    const [tagWidth, setTagWidth] = useState(0);
    const [number, setNumber] = useState(4);

    const styles = {
        height: height + 'px',
        width: height + 'px',
    };

    const stylesP = {
        left: -(tagWidth / number) + 'px',
    }

    const handleHover = (e) => {
        e.currentTarget.style.transform = `rotate(${rotate}deg) scale(1.1)`;
    };

    const handleBlur = (e) => {
        e.currentTarget.style.transform = 'rotate(0deg) scale(1)';
    };

    const handleClick = (e) => {
        e.currentTarget.style.transform = 'rotate(0deg) scale(0.9)';
    };

    const TagScreen = () => {
        const rect = tagRef.current.getBoundingClientRect();
        const height = window.innerHeight || document.documentElement.clientHeight;
        const width = window.innerWidth || document.documentElement.clientWidth;
        const Y = (rect.top / height) * 100;
        const X = (rect.left / width) * 100;

        setTagWidth(rect.width);

        if(Y > 90){
            setPosition('top');
        };

        if(X > 90){
            setPosition('center');
            setNumber(0.9);
        };
    };

    useLayoutEffect(() =>{
        TagScreen();
    },[])

    return (
        <span className='button-image' onClick={onclick}  style={styles}>
            <div>
                <img src={icon} onMouseEnter={handleHover} onMouseLeave={handleBlur} onClick={handleClick}/>
                <p style={stylesP} ref={tagRef} className={position}>{text}</p>
            </div>
        </span>
    )
}
