import React, { useEffect, useState } from 'react'
import { LayoutForm } from '../layout/LayoutForm'
import { InputBox } from '../ui/InputBox'
import { useLoader } from '../../utils/contexts/ContextLoader';
import { APIalignnet } from '../../utils/routes/api';
import { Fetch } from '../../utils/functions.js/fetch';
import { setCookie } from '../../utils/functions.js/cookies';
import { navDashboard } from '../../utils/routes/navigate';
import { useNavigate } from 'react-router-dom';
import { iconAlignnet, iconUser, imageLogin } from '../../utils/routes/assets';
import { LayoutFooter } from '../layout/LayoutFooter';
import { Image } from '../ui/Image';

export const PageLogin = () => {
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useLoader();
    const [ employeeNumber, setEmployeeNumber ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ qr, setQr ] = useState('');
    const [ legend, setLegend ] = useState('');

    const [isReadyForInstall, setIsReadyForInstall] = React.useState(false);

    useEffect(() => {
        window.addEventListener("beforeinstallprompt", (event) => {
          // Prevent the mini-infobar from appearing on mobile.
          event.preventDefault();
          //console.log("👍", "beforeinstallprompt", event);
          // Stash the event so it can be triggered later.
          window.deferredPrompt = event;
          // Remove the 'hidden' class from the install button container.
          setIsReadyForInstall(true);
        });
      }, []);

      async function downloadApp() {
        //console.log("👍", "butInstall-clicked");
        const promptEvent = window.deferredPrompt;
        if (!promptEvent) {
          // The deferred prompt isn't available.
          //console.log("oops, no prompt event guardado en window");
          return;
        }
        // Show the install prompt.
        promptEvent.prompt();
        // Log the result
        const result = await promptEvent.userChoice;
        //console.log("👍", "userChoice", result);
        // Reset the deferred prompt variable, since
        // prompt() can only be called once.
        window.deferredPrompt = null;
        // Hide the install button.
        setIsReadyForInstall(false);
    }


    const HandleSbmit = async () => {
        showLoader();
        setLegend('')

        const data = await Fetch({
            url: APIalignnet + 'login',
            params: {
                "employee_number": employeeNumber,
                "password": password,
                "qr": qr,
                "id_application": 1,
            }
        });

        console.log(data)

        if(data.code == 200){
            setCookie({ name: 'id_user', value: data.data.id_employee })
            navigate(navDashboard);

        }

        setLegend(data.message)
        hideLoader();
    };



    return (
        <div className='page-login'>
            <LayoutForm submit={HandleSbmit} title='Login' legend={legend} image={imageLogin}>
                <div className='grid-1'>
                  <InputBox hook={employeeNumber} setHook={setEmployeeNumber} placeholder={'Usuario'} icon={iconUser} required={true}/>
                  <InputBox hook={password} setHook={setPassword} placeholder={'Contraseña'} type='password' required={true}/>
                  {isReadyForInstall && <button onClick={downloadApp}>Descargar App</button>}
                </div>
            </LayoutForm>

            <LayoutFooter>
                <div>
                    <div className='logo'>
                        <Image image={iconAlignnet}/>
                    </div>
                </div>
            </LayoutFooter>
        </div>
    )
}
