import { useEffect, useState } from 'react';
import { Fetch } from '../functions.js/fetch';
import { APIalignnet } from '../routes/api';

export const useEmployee = () => {
    const [ firstName, setFirstName ] = useState('');
    const [ middleName, setMiddleName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ surname, setSurname ] = useState('');
    const [ fullName, setFullName ] = useState('');
    const [ birthdate, setBirthdate ] = useState('');
    const [ curp, setCurp ] = useState('');
    const [ rfc, setRfc ] = useState('');
    const [ nss, setNss ] = useState('');
    const [ nacionality, setNacionality ] = useState('');
    const [ gender, setGender ] = useState(1);
    const [ marritialStatus, setMarritialStatus ] = useState(1);
    const [ scholarShip, setScholarShip ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ street, setStreet ] = useState('');
    const [ interiorNumber, setInteriorNumber ] = useState('');
    const [ exteriorNumber, setExteriorNumber ] = useState('');
    const [ crossings, setCrossings ] = useState('');
    const [ colony, setColony ] = useState('');
    const [ code, setCode ] = useState('');
    const [ city, setCity ] = useState('');
    const [ state, setState ] = useState('Yucatán');
    const [ descriptionAddress, setDescriptionAddress ] = useState('');
    const [image, setImage] = useState(null);
    const [ employeeNumber, setEmployeeNumber ] = useState('');
    const [ hireDate, setHireDate ] = useState('');
    const [ department, setDepartment ] = useState(1);
    const [ shift, setShift ] = useState(1);
    const [ businessName, setBusinessName ] = useState(1);
    const [ position, setPosition ] = useState('');
    const [ descriptionPosition, setDescriptionPosition ] = useState('');
    const [ salary, setSalary ] = useState('');
    const [ productivityBonus, setProductivityBonus ] = useState('');
    const [ mealSubsidy, setMealSubsidy ] = useState('');
    const [ hasInfonavit, setHasInfonavit ] = useState(false);
    const [ hasFonacot, setHasFonacot ] = useState(false);
    const [ hasBonus1, setHasbonus1 ] = useState(false);
    const [ hasBonus2, setHasbonus2 ] = useState(false);
    const [ hasBonus3, setHasbonus3 ] = useState(false);
    const [ beneficiarieFirstName, setBeneficiarieFirstName ] = useState('');
    const [ beneficiarieMiddleName, setBeneficiarieMiddleName ] = useState('');
    const [ beneficiarieLastName, setBeneficiarieLastName ] = useState('');
    const [ beneficiarieSurname, setBeneficiarieSurname ] = useState('');
    const [ beneficiarieRelationship, setBeneficiarieRelationship ] = useState('');
    const [ beneficiariePhone, setBeneficiariePhone ] = useState('');
    const [ beneficiarieEmail, setBeneficiarieEmail ] = useState('');
    const [ shirtSize, setShirtSize ] = useState('');
    const [ status, setStatus ] = useState(1);
    const [ isActive, setIsActive ] = useState(false);
    const [ comments, setComments ] = useState([]);

    const [departments, setDepartments] = useState([]);
    const [shifts, setShifts] = useState([]);
    const [businessNames, setBusinessNames] = useState([]);
    const [statuses, setStatuses] = useState([]);

    const Clear = () => {
        setFirstName('');
        setMiddleName('');
        setLastName('');
        setSurname('');
        setCurp('');
        setRfc('');
        setNss('');
        setNacionality('');
        setGender(1);
        setMarritialStatus(1);
        setScholarShip('');
        setPhone('');
        setEmail('');
        setStreet('');
        setInteriorNumber('');
        setExteriorNumber('');
        setCrossings('');
        setColony('');
        setCode('');
        setCity('');
        setState('Yucatán');
        setDescriptionAddress('');
        setImage(null);
        setEmployeeNumber('');
        setHireDate('');
        setDepartment(1);
        setShift(1);
        setBusinessName(1);
        setPosition('');
        setDescriptionPosition('');
        setSalary('');
        setProductivityBonus('');
        setMealSubsidy('');
        setHasInfonavit(false);
        setHasFonacot(false);
        setHasbonus1(false);
        setHasbonus2(false);
        setHasbonus3(false);
        setBeneficiarieFirstName('');
        setBeneficiarieMiddleName('');
        setBeneficiarieLastName('');
        setBeneficiarieSurname('');
        setBeneficiarieRelationship('');
        setBeneficiariePhone('');
        setBeneficiarieEmail('');
        setShirtSize('');
    }
    const getDepartments = async () => {
        const data = await Fetch({ url: APIalignnet + 'departments', });
        setDepartments(data.data);
    };
    const getShifts = async () => {
        const data = await Fetch({ url: APIalignnet + 'shifts', });
        setShifts(data.data);
    };
    const getBusinessNames = async () => {
        const data = await Fetch({ url: APIalignnet + 'businessNames', });
        setBusinessNames(data.data);
    };
    const getStatuses = async () => {
        const data = await Fetch({ url: APIalignnet + 'statuses', });
        setStatuses(data.data);
    }
    const GetEmployee = async (id) => {
        const data = await Fetch({
            url: APIalignnet + 'employee/' + id,
            params: {
                "column": "id",
                "address": true,
                "benficiaries": true,
                "benefits": true,
                "bonus": true,
                "contact": true,
                "details": true,
                "job": true,
                "sizes": true,
            }
        });
        
        if(data.data.image){ setImage(data.data.image); }
        if(data.data.first_name){ setFirstName(data.data.first_name); }
        if(data.data.middle_name){ setMiddleName(data.data.middle_name); }
        if(data.data.last_name){ setLastName(data.data.last_name); }
        if(data.data.surname){ setSurname(data.data.surname); }
        if(data.data.user){ setFullName(data.data.user); }
        if(data.data.employee_number){ setEmployeeNumber(data.data.employee_number); }
        if(data.data.hire_date){ setHireDate(data.data.hire_date); }
        if(data.data.id_status){ setStatus(data.data.id_status); }
        if(data.data.is_active){setIsActive(data.data.is_active); }

        if(data.data.details && data.data.details.curp){ setCurp(data.data.details.curp); }
        if(data.data.details && data.data.details.rfc){ setRfc(data.data.details.rfc); }
        if(data.data.details && data.data.details.nss){ setNss(data.data.details.nss); }
        if(data.data.details && data.data.details.nacionality){ setNacionality(data.data.details.nacionality); }
        if(data.data.details && data.data.details.gender){ setGender(data.data.details.gender); }
        if(data.data.details && data.data.details.marritialStatus){ setMarritialStatus(data.data.details.marritialStatus); }
        if(data.data.details && data.data.details.scholarship){ setScholarShip(data.data.details.scholarship); }

        if(data.data.contact && data.data.contact.phone){setPhone(data.data.contact.phone); }
        if(data.data.contact && data.data.contact.email){ setEmail(data.data.contact.email); }

        if(data.data.address && data.data.address.street){ setStreet(data.data.address.street); }
        if(data.data.address && data.data.address.interior_number){ setInteriorNumber(data.data.address.interior_number); }
        if(data.data.address && data.data.address.exterior_number){ setExteriorNumber(data.data.address.exterior_number); }
        if(data.data.address && data.data.address.crossings){ setCrossings(data.data.address.crossings); }
        if(data.data.address && data.data.address.colony){ setColony(data.data.address.colony); }
        if(data.data.address && data.data.address.code){ setCode(data.data.address.code); }
        if(data.data.address && data.data.address.city){ setCity(data.data.address.city); }
        if(data.data.address && data.data.address.state){ setState(data.data.address.state); }
        if(data.data.address && data.data.address.description){ setDescriptionAddress(data.data.address.description); }

        if(data.data.job && data.data.job.id_department){ setDepartment(data.data.job.id_department); }
        if(data.data.job && data.data.job.id_shifht){ setShift(data.data.job.id_shifht); }
        if(data.data.job && data.data.job.id_business_name){ setBusinessName(data.data.job.id_business_name); }
        if(data.data.job && data.data.job.position){ setPosition(data.data.job.position); }
        if(data.data.job && data.data.job.description){ setDescriptionPosition(data.data.job.description); }
        if(data.data.job && data.data.job.salary){ setSalary(data.data.job.salary); }

        if(data.data.benefits && data.data.benefits.productivity_bonus){ setProductivityBonus(data.data.benefits.productivity_bonus); }
        if(data.data.benefits && data.data.benefits.meal_subsidy){ setMealSubsidy(data.data.benefits.meal_subsidy); }
        if(data.data.benefits && data.data.benefits.has_infonavit){ setHasInfonavit(data.data.benefits.has_infonavit); }
        if(data.data.benefits && data.data.benefits.has_fonacot){ setHasFonacot(data.data.benefits.has_fonacot); }

        for (let key in data.data.bonus) {
            if (data.data.bonus[key].shortcut == 'VD') { setHasbonus1(true) }
            if (data.data.bonus[key].shortcut == 'PA') { setHasbonus2(true) }
            if (data.data.bonus[key].shortcut == 'BT') { setHasbonus3(true) }
        }

        if(data.data.benficiaries && data.data.benficiaries[0] && data.data.benficiaries[0].first_name){ setBeneficiarieFirstName(data.data.benficiaries[0].first_name); }
        if(data.data.benficiaries && data.data.benficiaries[0] && data.data.benficiaries[0].middle_name){ setBeneficiarieMiddleName(data.data.benficiaries[0].middle_name); }
        if(data.data.benficiaries && data.data.benficiaries[0] && data.data.benficiaries[0].last_name){ setBeneficiarieLastName(data.data.benficiaries[0].last_name); }
        if(data.data.benficiaries && data.data.benficiaries[0] && data.data.benficiaries[0].surname){ setBeneficiarieSurname(data.data.benficiaries[0].surname); }
        if(data.data.benficiaries && data.data.benficiaries[0] && data.data.benficiaries[0].relationship){ setBeneficiarieRelationship(data.data.benficiaries[0].relationship); }
        if(data.data.benficiaries && data.data.benficiaries[0] && data.data.benficiaries[0].phone){ setBeneficiariePhone(data.data.benficiaries[0].phone); }
        if(data.data.benficiaries && data.data.benficiaries[0] && data.data.benficiaries[0].email){ setBeneficiarieEmail(data.data.benficiaries[0].email); }

        if(data.data.sizes &&  data.data.sizes.shirt_size){ setShirtSize(data.data.sizes.shirt_size); }
    }
    const GetComments = async (id) => {
        const data = await Fetch({ url: APIalignnet + 'comments/'  + id, });
        setComments(data.data);
    }
    const UpdateEmployee = async (id) => {
        const formData = new FormData();
        formData.append("id_status", status);
        formData.append("is_active", isActive);
        formData.append("first_name", firstName);
        formData.append("middle_name", middleName);
        formData.append("last_name", lastName);
        formData.append("surname", surname);
        formData.append("hire_date", hireDate);
        formData.append("employee_number", employeeNumber);
        if(image){ formData.append("image", image);}
        
        const data = await Fetch({
            url: APIalignnet + 'update/employee/' + id,
            method: 'post',
            data: formData,
            headers: {
                'Content-Type' : 'multipart/form-data'
            },
        });
    };
    const UpdateDetails = async (id) => {
        const formData = new FormData();
        formData.append("id_employee", id);
        formData.append("curp", curp);
        formData.append("rfc", rfc);
        formData.append("nss", nss);
        formData.append("nacionality", nacionality);
        formData.append("gender", gender);
        formData.append("marritial_status", marritialStatus);
        formData.append("scholarship", scholarShip);
    
        await Fetch({
            url: APIalignnet + 'update/details/' + id,
            method: 'post',
            data: formData,
        });
    };
    const UpdateContact = async (id) => {
        const formData = new FormData();
        formData.append("id_employee", id);
        formData.append("phone", phone);
        formData.append("email", email);
    
        await Fetch({
            url: APIalignnet + 'update/contact/' + id,
            method: 'post',
            data: formData,
        });
    }
    const UpdateAddress = async (id) => {
        const formData = new FormData();
        formData.append("id_employee", id);
        formData.append("street", street);
        formData.append("interior_number", interiorNumber);
        formData.append("exterior_number", exteriorNumber);
        formData.append("crossings", crossings);
        formData.append("colony", colony);
        formData.append("code", code);
        formData.append("city", city);
        formData.append("state", state);
        formData.append("description", descriptionAddress);
    
        await Fetch({
            url: APIalignnet + 'update/address/' + id,
            method: 'post',
            data: formData,
        });
    }
    const UpdateJob = async (id) => {
        const formData = new FormData();
        formData.append("id_employee", id);
        formData.append("id_department", department);
        formData.append("id_shift", shift);
        formData.append("id_business_name", businessName);
        formData.append("position", position);
        formData.append("description", descriptionPosition);
        formData.append("salary", salary);
    
        await Fetch({
            url: APIalignnet + 'update/job/' + id,
            method: 'post',
            data: formData,
        });
    }
    const UpdateBenefit = async (id) => {
        const formData = new FormData();
        formData.append("id_employee", id);
        formData.append("productivity_bonus", productivityBonus);
        formData.append("meal_subsidy", mealSubsidy);
        formData.append("has_infonavit", hasInfonavit);
        formData.append("has_fonacot", hasFonacot);
    
        await Fetch({
            url: APIalignnet + 'update/benefit/' + id,
            method: 'post',
            data: formData,
        });
    }
    const UpdateBonus = async (id, ) => {
        const formData = new FormData();
        formData.append("id_employee", id);

        formData.append("value", hasBonus1);
        formData.append("id_bonus", 1);
        await Fetch({
            url: APIalignnet + 'update/bonus/' + id,
            method: 'post',
            data: formData,
        });

        formData.append("value", hasBonus2);
        formData.append("id_bonus", 2);
        await Fetch({
            url: APIalignnet + 'update/bonus/' + id,
            method: 'post',
            data: formData,
        });

        formData.append("value", hasBonus3);
        formData.append("id_bonus", 3);
        await Fetch({
            url: APIalignnet + 'update/bonus/' + id,
            method: 'post',
            data: formData,
        });
    }
    const UpdateBeneficiary = async (id) => {
        if(beneficiarieFirstName && beneficiarieLastName){
            const formData = new FormData();
            formData.append("id_employee", id);
            formData.append("first_name", beneficiarieFirstName);
            formData.append("middle_name", beneficiarieMiddleName);
            formData.append("last_name", beneficiarieLastName);
            formData.append("surname", beneficiarieSurname);
            formData.append("relationship", beneficiarieRelationship);
            formData.append("phone", beneficiariePhone);
            formData.append("email", beneficiarieEmail);
        
            await Fetch({
                url: APIalignnet + 'update/beneficiary/' + id,
                method: 'post',
                data: formData,
            });
        }
    }
    const UpdateSizes = async (id) => {
        const formData = new FormData();
        formData.append("id_employee", id);
        formData.append("shirt_size", shirtSize);
    
        const data = await Fetch({
            url: APIalignnet + 'update/size/' + id,
            method: 'post',
            data: formData,
        });
    }
    const CreateEmployee = async (id) => {
        const formData = new FormData();
        formData.append("first_name", firstName);
        formData.append("middle_name", middleName);
        formData.append("last_name", lastName);
        formData.append("surname", surname);
        formData.append("hire_date", hireDate);
        formData.append("employee_number", employeeNumber);
        if(image){formData.append("image", image);}

        const data = await Fetch({
            url: APIalignnet + 'employee',
            method: 'post',
            data: formData,
            headers: {
                'Content-Type' : 'multipart/form-data'
            },
        });

        return data.data.id_employee;
    }
    const CreateAddress = async (id) => {
        if(street || interiorNumber || exteriorNumber || crossings || colony || code || descriptionAddress){
            const formData = new FormData();
            formData.append("id_employee", id);
            formData.append("street", street);
            formData.append("interior_number", interiorNumber);
            formData.append("exterior_number", exteriorNumber);
            formData.append("crossings", crossings);
            formData.append("colony", colony);
            formData.append("code", code);
            formData.append("city", city);
            formData.append("state", state);
            formData.append("description", descriptionAddress);
    
            await Fetch({
                url: APIalignnet + 'employee/address',
                method: 'post',
                data: formData,
            });
        }
    }
    const CreateBeneficiary = async (id) => {
        if(beneficiarieFirstName && beneficiarieLastName){
            const formData = new FormData();
            formData.append("id_employee", id);
            formData.append("first_name", beneficiarieFirstName);
            formData.append("middle_name", beneficiarieMiddleName);
            formData.append("last_name", beneficiarieLastName);
            formData.append("surname", beneficiarieSurname);
            formData.append("relationship", beneficiarieRelationship);
            formData.append("phone", beneficiariePhone);
            formData.append("email", beneficiarieEmail);
    
            await Fetch({
                url: APIalignnet + 'employee/beneficiary',
                method: 'post',
                data: formData,
            });
        }
    }
    const CreateBenefit = async (id) => {
        if(productivityBonus && mealSubsidy){
            const formData = new FormData();
            formData.append("id_employee", id);
            formData.append("productivity_bonus", productivityBonus);
            formData.append("meal_subsidy", mealSubsidy);
            formData.append("has_infonavit", hasInfonavit);
            formData.append("has_fonacot", hasFonacot);
    
            await Fetch({
                url: APIalignnet + 'employee/benefit',
                method: 'post',
                data: formData,
            });
        }
    }
    const CreateBonus = async (id) => {
        const formData = new FormData();
        formData.append("id_employee", id);

        if(hasBonus1){
            formData.append("id_bonus", 1);
            await Fetch({
                url: APIalignnet + 'employee/bonus',
                method: 'post',
                data: formData,
            });
        }

        if(hasBonus2){
            formData.append("id_bonus", 2);
            await Fetch({
                url: APIalignnet + 'employee/bonus',
                method: 'post',
                data: formData,
            });
        }

        if(hasBonus3){
            formData.append("id_bonus", 3);
            await Fetch({
                url: APIalignnet + 'employee/bonus',
                method: 'post',
                data: formData,
            });
        }
    }
    const CreateContact = async (id) => {
        if(phone || email){
            const formData = new FormData();
            formData.append("id_employee", id);
            formData.append("phone", phone);
            formData.append("email", email);
    
            await Fetch({
                url: APIalignnet + 'employee/contact',
                method: 'post',
                data: formData,
            });
        }
    }
    const CreateDetail = async (id) => {
        if(curp || rfc || nss | nacionality || scholarShip  || birthdate){
            const formData = new FormData();
            formData.append("id_employee", id);
            formData.append("curp", curp);
            formData.append("rfc", rfc);
            formData.append("nss", nss);
            formData.append("nacionality", nacionality);
            formData.append("gender", gender);
            formData.append("marritial_status", marritialStatus);
            formData.append("scholarship", scholarShip);
            formData.append("birthdate", birthdate);

            await Fetch({
                url: APIalignnet + 'employee/detail',
                method: 'post',
                data: formData,
            });
        }
    }
    const CreateJob = async (id) => {
        if(department && businessName){
            const formData = new FormData();
            formData.append("id_employee", id);
            formData.append("id_department", department);
            formData.append("id_shift", shift);
            formData.append("id_business_name", businessName);
            formData.append("position", position);
            formData.append("description", descriptionPosition);
            formData.append("salary", salary);

            await Fetch({
                url: APIalignnet + 'employee/job',
                method: 'post',
                data: formData,
            });
        }
    }
    const CreateSize = async (id) => {
        if(shirtSize){
            const formData = new FormData();
            formData.append("id_employee", id);
            formData.append("shirt_size", shirtSize);


            await Fetch({
                url: APIalignnet + 'employee/size',
                method: 'post',
                data: formData,
            });
        }
    }

    useEffect(() => {
        getDepartments();
        getShifts();
        getBusinessNames();
        getStatuses();
    }, []);

    return {
        firstName, setFirstName,
        middleName, setMiddleName,
        lastName, setLastName,
        surname, setSurname,
        fullName, setFullName,
        birthdate, setBirthdate,
        curp, setCurp,
        rfc, setRfc,
        nss, setNss,
        nacionality, setNacionality,
        gender, setGender,
        marritialStatus, setMarritialStatus,
        scholarShip, setScholarShip,
        phone, setPhone,
        email, setEmail,
        street, setStreet,
        interiorNumber, setInteriorNumber,
        exteriorNumber, setExteriorNumber,
        crossings, setCrossings,
        colony, setColony,
        code, setCode,
        city, setCity,
        state, setState,
        descriptionAddress, setDescriptionAddress,
        image, setImage,
        employeeNumber, setEmployeeNumber,
        hireDate, setHireDate,
        department, setDepartment,
        shift, setShift,
        businessName, setBusinessName,
        position, setPosition,
        descriptionPosition, setDescriptionPosition,
        salary, setSalary,
        productivityBonus, setProductivityBonus,
        mealSubsidy, setMealSubsidy,
        hasInfonavit, setHasInfonavit,
        hasFonacot, setHasFonacot,
        hasBonus1, setHasbonus1,
        hasBonus2, setHasbonus2,
        hasBonus3, setHasbonus3,
        beneficiarieFirstName, setBeneficiarieFirstName,
        beneficiarieMiddleName, setBeneficiarieMiddleName,
        beneficiarieLastName, setBeneficiarieLastName,
        beneficiarieSurname, setBeneficiarieSurname,
        beneficiarieRelationship, setBeneficiarieRelationship,
        beneficiariePhone, setBeneficiariePhone,
        beneficiarieEmail, setBeneficiarieEmail,
        shirtSize, setShirtSize,
        status, setStatus,
        isActive, setIsActive,

        departments,
        shifts,
        businessNames,
        statuses,
        comments,

        Clear,
        GetEmployee,
        GetComments,

        UpdateEmployee,
        UpdateDetails,
        UpdateContact,
        UpdateAddress,
        UpdateJob,
        UpdateBenefit,
        UpdateBonus,
        UpdateBeneficiary,
        UpdateSizes,

        CreateEmployee,
        CreateAddress,
        CreateBeneficiary,
        CreateBenefit,
        CreateBonus,
        CreateContact,
        CreateDetail,
        CreateJob,
        CreateSize,
    };
}