import React from 'react'
import { LayoutNavbar } from '../components/layout/LayoutNavbar'
import { PageDashboard } from '../components/pages/PageDashboard'

export const ViewDashboard = () => {
  return (
    <main>
        <LayoutNavbar/>
        <PageDashboard/>
    </main>
  )
}
