import React from 'react'
import { LayoutNavbar } from '../components/layout/LayoutNavbar'
import { PageStats } from '../components/pages/PageStats'

export const ViewStats = () => {
    return (
        <main>
            <LayoutNavbar/>
            <PageStats/>
        </main>
    )
}
