import Swal from "sweetalert2"
import { Fetch } from "./fetch";
import { APIalignnet } from "../routes/api";

export const SwalSucess=()=>{
    Swal.fire({
        title: 'Enviado exitoso',
        text: 'Datos enviados correctamente',
        icon: 'success',
        confirmButtonText: 'Aceptar',
        timer: 2000,
    })
}

export const SwalCreateComment=({ id, id_user })=>{
    Swal.fire({
        title: 'Agregar comentario',
        input: 'textarea',
        showCancelButton: true,
        confirmButtonText: 'Enviar',
        cancelButtonText: 'Cancelar',

    }).then((result) => {
        async function Submit() {
            const formData = new FormData();
            formData.append("id_employee", id);
            formData.append("id_user", id_user);
            formData.append("comment", result.value);
    
            if (result.value.trim() !== '') {
                await Fetch({
                    url: APIalignnet + 'employee/comment',
                    method: 'post',
                    data: formData,
                });
                SwalSucess();
            }
        }
        
        if (result.isConfirmed && result.value) {
            Submit();
        }
    });
}

export const SwalImage=(image)=>{
    Swal.fire({
        imageUrl: image,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: false,
        showDenyButton: false,
        customClass: {
            popup: 'custom-popup',
            image: 'custom-image'
        },
    });
}