import React from 'react'

export const TextAreaBox = ({ placeholder, hook, setHook }) => {

    const HandleChange = (event) => {
        setHook(event.target.value);
    };


    return (
        <label className='textarea-box'>
            <p>{placeholder}</p>

            <textarea
                value={hook}
                onChange={HandleChange}
            />
        </label>
    )
}
