import React from 'react'

export const Loader = () => {
    return (
        <div className='loader-background'>
            <div className='loader'></div>
        </div>
    )
}

export const LayoutLoader = () => {
    return (
        <div className='loader-content'>
            <div className='loader'></div>
        </div>
    )
}