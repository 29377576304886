import React from 'react'
import { LayoutNavbar } from '../components/layout/LayoutNavbar'
import { PageScoop } from '../components/pages/PageScoop'

export const ViewScoop = () => {
  return (
        <main>
            <LayoutNavbar/>
            <PageScoop/>
        </main>
    )
}
