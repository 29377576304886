import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useEmployee } from '../../utils/hooks/useEmployee';
import { LayoutUserProfile } from '../layout/LayoutUserProfile';
import { LayoutSections } from '../layout/LayoutSections';
import { InputBox } from '../ui/InputBox';
import { SelectBox } from '../ui/SelectBox';
import { genders } from '../../utils/objects/genders';
import { marritialStatuses } from '../../utils/objects/marritialStatuses';
import { InputToggle } from '../ui/InputToggle';
import { getCookie } from '../../utils/functions.js/cookies';
import { Fetch } from '../../utils/functions.js/fetch';
import { APIalignnet } from '../../utils/routes/api';
import { iconEdit, iconEye, iconSave, iconSend } from '../../utils/routes/assets';
import { ButtonImage } from '../ui/ButtonImage';
import { InputImage } from '../ui/InputImage';
import { LayoutFooter } from '../layout/LayoutFooter';
import { useLoader } from '../../utils/contexts/ContextLoader';
import { SwalSucess } from '../../utils/functions.js/swal';
import { Image } from '../ui/Image';
import Swal from 'sweetalert2';
import { TextAreaBox } from '../ui/TextAreaBox';

export const PageEmployee = () => {
    const { id } = useParams();
    const employee = useEmployee();
    const { showLoader, hideLoader } = useLoader();
    const role = getCookie('role');
    const sections = ['Datos', 'Detalles', 'Contacto', 'Domicilio', 'Departamento', 'Beneficios', 'Bonos', 'Beneficiario', 'Tallas', 'Cuentas', 'Comentarios'];
    const [ section, setSection ] = useState(0);
    const [ comment, setComment ] = useState('');
    const [ blocked, setBlocked ] = useState(true);
    const id_user = getCookie('id_user');
    const [ buttonSave, setButtonSave ] = useState(false);
    const [ applications, setApplications ] = useState([]);

    async function SubmitComment() {
        const formData = new FormData();
        formData.append("id_employee", id);
        formData.append("id_user", id_user);
        formData.append("comment", comment);

        if (comment.trim() !== '') {
             await Fetch({
                url: APIalignnet + 'employee/comment',
                method: 'post',
                data: formData,
            });
            setComment('');
            employee.GetComments(id);
        }
    }

    function HandleBlocked(){
        setBlocked(!blocked);
        employee.GetEmployee(id);
    }



    async function UpdateData(){
        Swal.fire({
            icon: 'question',
            title: 'Confirmar',
            text: '¿Guardar cambios?',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
    
        }).then(async (result) => {
            if (result.isConfirmed) {
                showLoader();
    
                await employee.UpdateEmployee(id);
                await employee.UpdateDetails(id);
                await employee.UpdateContact(id);
                await employee.UpdateAddress(id);
                await employee.UpdateJob(id);
                await employee.UpdateBenefit(id);
                await employee.UpdateBonus(id);
                await employee.UpdateBeneficiary(id);
                await employee.UpdateSizes(id);
                await employee.GetEmployee(id);
    
                hideLoader();
                SwalSucess();
            }
        });
    }

    async function GetData(){
        showLoader();
        await employee.GetEmployee(id);
        await employee.GetComments(id);
        hideLoader();
    }

    const GetAccounts = async () => {

        const data = await Fetch({
            url: APIalignnet + 'accounts/' + id ,
        });

        let apps = [];

        data.data.forEach(element => {
            if(element.level == 1){apps.push(element);}
        });

        setApplications(apps);
    }

    async function UpdateAccount( id_application, value ){
        const formData = new FormData();
        formData.append("id_employee", id);
        formData.append("role", 100);

        formData.append("id_application", id_application);
        formData.append("is_active", value);

        await Fetch({
            url: APIalignnet + 'update/account/' + id,
            method: 'post',
            data: formData,
        });

        GetAccounts();
    }

    useEffect(()=>{
        GetData();
        GetAccounts();
    },[])

    useEffect(()=>{
        if(employee.firstName.trim() !== '' && employee.lastName.trim() !== ''){setButtonSave(true)}
        if(employee.firstName.trim() == '' || employee.lastName.trim() == ''){setButtonSave(false)}
    },[employee.firstName, employee.lastName])

    return (
        <div className='page page-employee'>
            
            <div className='profile'>
                <LayoutUserProfile
                    image={employee.image}
                    name={employee.firstName + ' ' + employee.middleName + ' ' + employee.lastName + ' ' + employee.surname}
                    employeeNumber={employee.employeeNumber}
                    hireDate={employee.hireDate}
                    isActive={employee.isActive}
                    status={employee.status}
                />
            </div>

            <div className='sections'>
                <LayoutSections headers={sections} hook={section} setHook={setSection}>

                    {section == 0 &&
                        <form className='data'>
                            <div className='image'><InputImage hook={employee.image} setHook={employee.setImage} blocked={blocked}/></div>

                            <div className='grid-4 info'>
                                <InputBox hook={employee.firstName} setHook={employee.setFirstName} placeholder={'Nombre'} blocked={blocked} required={true}/>
                                <InputBox hook={employee.middleName} setHook={employee.setMiddleName} placeholder={'Segundo nombre'} blocked={blocked}/>
                                <InputBox hook={employee.lastName} setHook={employee.setLastName} placeholder={'Apellido paterno'} blocked={blocked} required={true}/>
                                <InputBox hook={employee.surname} setHook={employee.setSurname} placeholder={'Apellido materno'} blocked={blocked}/>

                                <InputBox hook={employee.employeeNumber} setHook={employee.setEmployeeNumber} placeholder={'Número de empleado'} blocked={blocked} type='number'/>
                                <InputBox hook={employee.hireDate} setHook={employee.setHireDate} placeholder={'Fecha de ingreso'} blocked={blocked} type={'date'}/>
                                <SelectBox data={employee.statuses} hook={employee.status} setHook={employee.setStatus} placeholder='Status' blocked={blocked}/>
                                <div></div>

                                <InputToggle placeholder={'Activo'} hook={employee.isActive} setHook={employee.setIsActive} blocked={blocked} height={30}/>
                            </div>
                        </form>
                    }

                    {section == 1 &&
                        <form>     
                            <div className='grid-4'>
                                <InputBox hook={employee.curp} setHook={employee.setCurp} placeholder={'CURP'} blocked={blocked}/>
                                <InputBox hook={employee.rfc} setHook={employee.setRfc} placeholder={'RFC'} blocked={blocked}/>
                                <InputBox hook={employee.nss} setHook={employee.setNss} placeholder={'NSS'} blocked={blocked}/>
                                <InputBox hook={employee.nacionality} setHook={employee.setNacionality} placeholder={'Nacionalidad'} blocked={blocked}/>

                                <SelectBox hook={employee.gender} setHook={employee.setGender} data={genders} placeholder='Género' blocked={blocked}/>
                                <SelectBox hook={employee.marritialStatus} setHook={employee.setMarritialStatus} data={marritialStatuses} placeholder='Estado civil' blocked={blocked}/>
                                <InputBox hook={employee.scholarShip} setHook={employee.setScholarShip} placeholder={'Escolaridad'} blocked={blocked}/>
                                <div></div>
                            </div>

                        </form>
                    }

                    {section == 2 &&
                        <form>     
                            <div className='grid-4'>
                                <InputBox hook={employee.phone} setHook={employee.setPhone} placeholder={'Teléfono'} blocked={blocked}/>
                                <InputBox hook={employee.email} setHook={employee.setEmail} placeholder={'Correo'} blocked={blocked}/>
                                <div></div>
                                <div></div>
                            </div>
                        </form>
                    }

                    {section == 3 &&
                        <form>     
                            <div className='grid-4'>
                                <InputBox hook={employee.street} setHook={employee.setStreet} placeholder={'Calle'} blocked={blocked}/>
                                <InputBox hook={employee.interiorNumber} setHook={employee.setInteriorNumber} placeholder={'Número interior'} blocked={blocked}/>
                                <InputBox hook={employee.exteriorNumber} setHook={employee.setExteriorNumber} placeholder={'Número exterior'} blocked={blocked}/>
                                <InputBox hook={employee.crossings} setHook={employee.setCrossings} placeholder={'Cruzamientos'} blocked={blocked}/>
                                
                                <InputBox hook={employee.colony} setHook={employee.setColony} placeholder={'Colonia'} blocked={blocked}/>
                                <InputBox hook={employee.code} setHook={employee.setCode} placeholder={'Código postal'} blocked={blocked}/>
                                <InputBox hook={employee.city} setHook={employee.setCity} placeholder={'Ciudad'} blocked={blocked}/>
                                <InputBox hook={employee.state} setHook={employee.setState} placeholder={'Estado'} blocked={blocked}/>

                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                                
                            <div className='grid-1'>
                                <InputBox hook={employee.descriptionAddress} setHook={employee.setDescriptionAddress} placeholder={'Referencias'} blocked={blocked}/>
                            </div>
                        </form>
                    }

                    {section == 4 &&
                        <form>     
                            <div className='grid-3'>
                                <SelectBox data={employee.departments} hook={employee.department} setHook={employee.setDepartment} placeholder={'Departamento'} blocked={blocked}/>
                                <InputBox hook={employee.position} setHook={employee.setPosition} placeholder={'Posicion'} blocked={blocked}/>
                                <SelectBox data={employee.shifts} hook={employee.shift} setHook={employee.setShift} placeholder={'Turno'} blocked={blocked}/>
                                <SelectBox data={employee.businessNames} hook={employee.businessName} setHook={employee.setBusinessName} placeholder={'Razón social'} blocked={blocked}/>
                                <InputBox hook={employee.salary} setHook={employee.setSalary} placeholder={'Salario diario'} blocked={blocked}/>
                                <InputBox hook={employee.descriptionPosition} setHook={employee.setDescriptionPosition} placeholder={'Descripción'} blocked={blocked}/>
                            </div>
                        </form>
                    }

                    {section == 5 &&
                        <form>     
                            <div className='grid-2'>
                                <InputBox hook={employee.productivityBonus} setHook={employee.setProductivityBonus} placeholder={'Bono de productividad'} blocked={blocked}/>
                                <InputBox hook={employee.mealSubsidy} setHook={employee.setMealSubsidy} placeholder={'Subsidio de comida'} blocked={blocked}/>
                                <InputToggle hook={employee.hasInfonavit} setHook={employee.setHasInfonavit} placeholder={'Infonavit'} blocked={blocked} height={30}/>
                                <InputToggle hook={employee.hasFonacot} setHook={employee.setHasFonacot} placeholder={'Fonacot'} blocked={blocked} height={30}/>
                            </div>
                        </form>
                    }

                    {section == 6 &&
                        <form>     
                            <div className='grid-6'>
                                <InputToggle hook={employee.hasBonus1} setHook={employee.setHasbonus1} placeholder={'VD'} blocked={blocked} height={30}/>
                                <InputToggle hook={employee.hasBonus2} setHook={employee.setHasbonus2} placeholder={'PA'} blocked={blocked} height={30}/>
                                <InputToggle hook={employee.hasBonus3} setHook={employee.setHasbonus3} placeholder={'BT'} blocked={blocked} height={30}/>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </form>
                    }

                    {section == 7 &&
                        <form>     
                            <div className='grid-4'>
                                <InputBox placeholder={'Nombre'} hook={employee.beneficiarieFirstName} setHook={employee.setBeneficiarieFirstName} blocked={blocked}/>
                                <InputBox placeholder={'Segundo nombre'} hook={employee.beneficiarieMiddleName} setHook={employee.setBeneficiarieMiddleName} blocked={blocked}/>
                                <InputBox placeholder={'Apellido paterno'} hook={employee.beneficiarieLastName} setHook={employee.setBeneficiarieLastName} blocked={blocked}/>
                                <InputBox placeholder={'Apellido materno'} hook={employee.beneficiarieSurname} setHook={employee.setBeneficiarieSurname} blocked={blocked}/>
                                
                                <InputBox placeholder={'Parentezco'} hook={employee.beneficiarieRelationship} setHook={employee.setBeneficiarieRelationship} blocked={blocked}/>
                                <InputBox placeholder={'Teléfono'} hook={employee.beneficiariePhone} setHook={employee.setBeneficiariePhone} blocked={blocked}/>
                                <InputBox placeholder={'Email'} hook={employee.beneficiarieEmail} setHook={employee.setBeneficiarieEmail} blocked={blocked}/>
                                <div></div>
                            </div>
                        </form>
                    }

                    {section == 8 &&
                        <form>     
                            <div className='grid-4'>
                                <InputBox placeholder={'Talla de playera'} hook={employee.shirtSize} setHook={employee.setShirtSize} blocked={blocked}/>    
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </form>
                    }

                    {section == 9 &&
                        <form>     
                            <div className='grid-6'>
                                {applications.map((application, index) => ( 
                                    <div  key={index}>
                                        <p>{application.name}</p>
                                        <InputToggle hook={application.is_active} onchange={(e) => UpdateAccount(application.id, !application.is_active)} blocked={blocked} height={30}/>
                                    </div>
                                ))}
                            </div>
                        </form>
                    }

                    {section == 10 &&
                        <form className='comments'>     
                            {role >= 500 &&
                                <div className='create'>
                                    <TextAreaBox placeholder={'Agregar comentario'} hook={comment} setHook={setComment}/>
                                    <div className='button-send'><ButtonImage icon={iconSend} text={'Enviar'} onclick={SubmitComment} rotate={180} height={40}/></div>
                                </div>
                            }

                            {employee.comments.map((comment, index) => (
                                    <div className='comment' key={index}>
                                        <div className='image'><Image image={comment.image}/></div>

                                        <div className='data'>
                                            <div className='triangle'></div>
                                            <div className='text'>
                                                <div>
                                                    <header>
                                                        <p className='user'>{comment.user}</p>
                                                    </header>
                                                    <div>
                                                        <p>{comment.comment}</p>
                                                    </div>
                                                    <footer>
                                                        <p className='date'>{comment.date}</p>
                                                    </footer>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                        </form>
                    }

                </LayoutSections>
            </div>

            {role >= 900 &&
                <LayoutFooter>
                    <div>
                        {blocked &&
                            <div><ButtonImage icon={iconEye} text={'Modo lectura'} onclick={HandleBlocked} rotate={0}/></div>
                        }
    
                        {!blocked && buttonSave &&
                            <div><ButtonImage icon={iconSave} text={'Guardar cambios'} onclick={(e) => {UpdateData()}} rotate={0}/></div>
                        }
    
                        {!blocked &&
                            <div><ButtonImage icon={iconEdit} text={'Modo edición'} onclick={HandleBlocked} rotate={0}/></div>
                        }
                    </div>
                </LayoutFooter>
            }
        </div>
    )
}
