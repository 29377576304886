import React, { createContext, useContext, useState } from 'react';
import { Loader } from '../../components/layout/LayoutLoader';

const LoaderContext = createContext();

export const useLoader = () => useContext(LoaderContext);

export const LoaderProvider = ({ children }) => {
  const [visible, setVisible] = useState(false);
  
    const showLoader = () => setVisible(true);
    const hideLoader = () => setVisible(false);
  
    return (
      <LoaderContext.Provider value={{ showLoader, hideLoader }}>
        {children}
        {visible && <Loader />}
      </LoaderContext.Provider>
    );
  };