import React, { useEffect, useState } from 'react'
import { useTable } from '../../utils/hooks/useTable';
import { Fetch } from '../../utils/functions.js/fetch';
import { APIalignnet } from '../../utils/routes/api';
import { LayoutTable } from '../layout/LayoutTable';

export const PageScoop = () => {

    const table = useTable();
    const [ scoops, setScoops ] = useState([]);
    const [ scoop, setScoop ] = useState('');

    async function GetScoops({ page = 0, searching=''} = {} ){
        table.setLoading(true);
        const limit = 30;
        table.setSearch(searching);
        const offset = page * limit;

        const data = await Fetch({
            url: APIalignnet + 'scoops',
            params: {
                "limit": limit,
                "offset": offset,
                "search": searching,
            }
        });

        if(data.data.scoops.length == 0){ table.setEmpty(true) }
        if(data.data.scoops.length > 0){ table.setEmpty(false) }

        if(data.data.pages > 0){
            if( page >= 0 && page < data.data.pages){
                table.setPage(page);
                table.setTotalPages(data.data.pages);
                setScoops(data.data.scoops);
            }
        }

        if(data.data.pages == 0){
            table.setPage(page);
            setScoops(data.data.scoops);
        }
        table.setLoading(false);
    }

    async function GetLastScoop(){
        const data = await Fetch({ url: APIalignnet + 'scoop',});
        setScoop(data.data.file);
    }

    function showScoop(link){
        setScoop(link);
    }

    useEffect(() => {
        GetLastScoop();
    }, [])

    return (
        <div className='page page-scoops'>
            <LayoutTable functionChangePage={GetScoops} currentPage={table.page} totalPages={table.totalPages} loading={table.loading} empty={table.empty}>
                <div className='scoops'>
                    <header>
                        <div>
                            <div>Mes</div>
                            <div>Año</div>
                        </div>
                    </header>



                    {scoops.map((scoop, index) => {
                        return(
                            <div key={index} className='scoop'>
                                <div onClick={(e) => showScoop(scoop.file)}>
                                    <div>{scoop.month}</div>
                                    <div>{scoop.year}</div>
                                </div>
                            </div>
                    )})}
                </div>
            </LayoutTable>

            <div className='preview'>
                <iframe src={scoop}></iframe>
            </div>
        </div>
    )
}
