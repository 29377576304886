import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/css/styles.css';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { LoaderProvider } from './utils/contexts/ContextLoader';
import { navAddEmployee, navDashboard, navEmployee, navLogin, navScoop, navStats, navVacancies } from './utils/routes/navigate';
import { ViewLogin } from './views/ViewLogin';
import { ViewDashboard } from './views/ViewDashboard';
import { ViewEmployee } from './views/ViewEmployee';
import { ViewAddEmployee } from './views/ViewAddEmployee';
import { ViewVacancies } from './views/ViewVacancies';
import { ViewStats } from './views/ViewStats';
import { ViewScoop } from './views/ViewScoop';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <LoaderProvider>
    <HashRouter>
      <Routes>
        <Route path={navLogin} element={<ViewLogin/>} />
        <Route path={navDashboard} element={<ViewDashboard/>} />
        <Route path={navEmployee + ':id'} element={<ViewEmployee/>} />
        <Route path={navAddEmployee} element={<ViewAddEmployee role={900}/>} />
        <Route path={navVacancies} element={<ViewVacancies/>} />
        <Route path={navStats} element={<ViewStats/>} />
        <Route path={navScoop} element={<ViewScoop/>} />
      </Routes>
    </HashRouter>
  </LoaderProvider>
);