import React from 'react'

export const LayoutForm = ({ image, title='title', legend, btnText='Enviar', submit, children }) => {
    
    function SendForm(e){
        e.preventDefault();
        submit();
    }
    
    
    return (
            <form className='layout-form'>
                
                {image && <div className='image'><img src={image} /></div>}

                <div className='container'>
                    <div>
                        <header><h1>{title}</h1></header>
                        <div>{children}</div>
                        <legend>{legend}</legend>
                        <footer><button className='btn-submit' onClick={(e)=> SendForm(e)}>{btnText}</button></footer>
                    </div>
                </div>

            </form>
    )
}
