import React from 'react'
import { LayoutNavbar } from '../components/layout/LayoutNavbar'
import { PageVacancies } from '../components/pages/PageVacancies'

export const ViewVacancies = () => {
    return (
        <main>
            <LayoutNavbar/>
            <PageVacancies/>
        </main>
    )
}
