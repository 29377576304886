import React from 'react'

export const SelectBox = ({ data, value='id', name='name', hook, setHook, onchange, placeholder='placeholder', blocked=false }) => {
    function onchangeFunction(e){
        if(!blocked){
            setHook(e.target.value);

            if(onchange){
                onchange();
            }
        }
    }
    
    return (
        <label className={blocked ? 'select-box select-box-blocked' : 'select-box'}>
            <p>{placeholder}</p>

            <select value={hook} onChange={(e) => onchangeFunction(e) }>
                {data.map((item, index) => (
                    <option key={index} value={item[value]}>{item[name]}</option>
                ))}
            </select>
        </label>
    )
}
