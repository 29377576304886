import React from 'react'
import { PageLogin } from '../components/pages/PageLogin'

export const ViewLogin = () => {


  return (
    <main>
      <PageLogin/>
    </main>
  )
}
