import React from 'react'
import { iconComment, iconUser } from '../../utils/routes/assets'
import { navEmployee } from '../../utils/routes/navigate';
import { useNavigate } from 'react-router-dom';
import { ButtonImage } from './ButtonImage';
import { getCookie } from '../../utils/functions.js/cookies';
import { SwalCreateComment } from '../../utils/functions.js/swal';

export const CardUser = ({ id, name, number, image, department, businessName, status, statusName, totalStatuses, isActive }) => {
    const id_user = getCookie('id_user');
    const navigate = useNavigate();

    function OpenCard(){
        navigate(navEmployee + id);
    }

    const HandleCommentClick = (e) => {
        e.stopPropagation();

        SwalCreateComment({
            id: id,
            id_user: id_user,
        });
    }

    return (
        <div className='card-user' onClick={(e) => OpenCard()}>
            <header>
                <div>
                    <h3>{name}</h3>
                    <span><span className={isActive ? 'actived': ''}></span></span>
                </div>
            </header>

            <div><div><img src={image ? image : iconUser} /></div></div>

            <footer>
                <div>
                    <div className='data'>
                        <span className='department'>{department}</span>
                        <span className='bussiness-name'>{businessName}</span>
                        <span className='status'>{status}/{totalStatuses} {statusName}</span>
                    </div>

                    <div className='actions'>
                        <span className='number'>{number}</span>
                        <div className='button-comment'><ButtonImage icon={iconComment} text={'Agregar Nota'} onclick={HandleCommentClick}/></div>
                    </div>
                </div>
            </footer>
        </div>
    )
}