import React, { useEffect, useState } from 'react'
import { iconClosedEye, iconExclamation, iconEye } from '../../utils/routes/assets'

export const InputBox = ({ type='text', placeholder, hook, setHook, icon, blocked=false, required=false, buzz=false }) => {
    const [inputType, setInputType] = useState(type);
    const [clicked, setClicked] = useState(false);
    const [empty, setEmpty] = useState(false);
    const [buzzing, setBuzzing] = useState(false);

    const classBlocked = blocked ? 'input-box-blocked ' : '';
    const classRequired = required && clicked && empty && !blocked ? 'input-box-required ' : '';
    const classBuzz = buzzing ? 'buzz ' : '';
    const classes = 'input-box ' + classBlocked + classRequired + classBuzz;

    const HandleChange = (e) => {
        if (!blocked) {
            setHook(e.target.value);
            setClicked(true);

            if (e.target.value.trim() === '') {
                setEmpty(true);
                HandleBuzz();
            }
            if (e.target.value.trim() !== '') { setEmpty(false); }
        }
    };

    const HandleBuzz = (e) => {
        setBuzzing(true);
        setClicked(true);
        setEmpty(true);
        setTimeout(() => {setBuzzing(false);}, 500);
    };

    useEffect(() =>{
        if(blocked == true){
            setClicked(false);
            setEmpty(false);
        }
    },[blocked])

    useEffect(() => {
        if (buzz === true && hook== '') {
            HandleBuzz();
        }
    }, [buzz]);

    const WatchPassword = () => {
        setInputType(prevInputType => prevInputType === 'password' ? 'text' : 'password');
    };

    return (
        <label className={classes}>
           <p>{placeholder}</p>

            <input
            type={inputType}
            value={hook}
            placeholder={blocked && hook === '' ? 'NA' : ''}
            onChange={(e) => (HandleChange(e))}
            />

            { required && !empty && type == 'password' && inputType == 'password' && <span><img className='watch' src={iconClosedEye} onClick={(e) => WatchPassword()} /></span> }
            { required && !empty && type == 'password' && inputType == 'text' && <span><img className='watch' src={iconEye} onClick={(e) => WatchPassword()} /></span> }
            { required && !empty && type == 'text' && <span><img src={icon} /></span> }

            { !required && type == 'password' && inputType == 'password' && <span><img className='watch' src={iconClosedEye} onClick={(e) => WatchPassword()} /></span> }
            { !required && type == 'password' && inputType == 'text' && <span><img className='watch' src={iconEye} onClick={(e) => WatchPassword()} /></span> }
            { !required && type != 'date' && type != 'password' && <span><img src={icon} /></span> }

            { required && clicked && empty && !blocked &&
                <span className='info'>
                    <img src={iconExclamation} />

                    <div className='alert'>
                        <div className='triangle'></div>
                        <div className='text'>
                                {required && clicked && empty ? <p>{placeholder} es requerido</p> : ''}
                        </div>
                    </div>
                </span>
            }
        </label>
    )
}
