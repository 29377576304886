import React, { useState } from 'react'
import { useEmployee } from '../../utils/hooks/useEmployee'
import { LayoutSteps } from '../layout/LayoutSteps'
import { InputBox } from '../ui/InputBox'
import { SelectBox } from '../ui/SelectBox'
import { genders } from '../../utils/objects/genders'
import { InputImage } from '../ui/InputImage'
import { marritialStatuses } from '../../utils/objects/marritialStatuses'
import { TextAreaBox } from '../ui/TextAreaBox'
import { InputToggle } from '../ui/InputToggle'
import { iconPercent } from '../../utils/routes/assets'
import Swal from 'sweetalert2'
import { SwalSucess } from '../../utils/functions.js/swal'
import { useLoader } from '../../utils/contexts/ContextLoader'

export const PageAddEmployee = () => {
    const employee = useEmployee();
    const { showLoader, hideLoader } = useLoader();
    const [ step, setStep ] = useState(1);
    const headers = ['Empleado', 'Detalles', 'Contacto', 'Domicilio', 'Departamento', 'Beneficios', 'Bonos', 'Beneficiario', 'Tallas'];
    const [ buzz, setBuzz ] = useState(false);

    function HandleNext(){

        if(step == 1){
            if(employee.firstName.trim() === '' || employee.lastName.trim() === ''){ setBuzz(true); }
            if(employee.firstName.trim() !== '' && employee.lastName.trim() !== ''){ setStep(step + 1); }
        }

        if(step != 1){setStep(step + 1);}
        setTimeout(() => {setBuzz(false);}, 500);
    }

    const SwalCreateEmployee = () => {
        Swal.fire({
            icon: 'question',
            title: 'Confirmar',
            text: '¿Crear empleado?',
            showCancelButton: true,
            confirmButtonText: 'Crear',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                showLoader();
                const id = await employee.CreateEmployee();
                await employee.CreateAddress(id);
                await employee.CreateBeneficiary(id);
                await employee.CreateBenefit(id);
                await employee.CreateBonus(id);
                await employee.CreateContact(id);
                await employee.CreateDetail(id);
                await employee.CreateJob(id);
                await employee.CreateSize(id);
                employee.Clear();
                setStep(1);
                hideLoader();
                SwalSucess();
            }
        });
    };


    return (
        <div className='page page-add-employee'>
            <LayoutSteps headers={headers} hook={step} setHook={setStep} next={HandleNext} submit={SwalCreateEmployee}>

                    { step == 1 &&
                    <div>
                        <div className='grid-3 grid-image'>
                            <div className='image'><InputImage hook={employee.image} setHook={employee.setImage}/></div>
                        </div>

                        <div className='grid-2'>
                            <InputBox hook={employee.firstName} setHook={employee.setFirstName} placeholder={'Nombre'} required={true} buzz={buzz}/>
                            <InputBox hook={employee.middleName} setHook={employee.setMiddleName} placeholder={'Segundo Nombre'}/>  
                            <InputBox hook={employee.lastName} setHook={employee.setLastName} placeholder={'Apellido Paterno'} required={true} buzz={buzz}/>
                            <InputBox hook={employee.surname} setHook={employee.setSurname} placeholder={'Apellido Materno'}/>
                            <InputBox hook={employee.employeeNumber} setHook={employee.setEmployeeNumber} placeholder={'Numero de empleado'}/>
                            <InputBox hook={employee.hireDate} setHook={employee.setHireDate} placeholder={'Fecha de entrada'} type='date'/>
                        </div>
                    </div>
                    }

                    { step == 2 &&
                        <div>
                            <div className='grid-2'>
                                <InputBox hook={employee.curp} setHook={employee.setCurp} placeholder={'CURP'}/>
                                <InputBox hook={employee.rfc} setHook={employee.setRfc} placeholder={'RFC'}/>  
                                <InputBox hook={employee.nss} setHook={employee.setNss} placeholder={'NSS'}/>
                                <InputBox hook={employee.nacionality} setHook={employee.setNacionality} placeholder={'Nacionalidad'}/>
                                <InputBox hook={employee.scholarShip} setHook={employee.setScholarShip} placeholder={'Escolaridad'}/>
                                <InputBox hook={employee.birthdate} setHook={employee.setBirthdate} placeholder={'Fecha de nacimiento'} type='date'/>
                                <SelectBox hook={employee.gender} setHook={employee.setGender} data={genders} placeholder={'Género'}/>
                                <SelectBox hook={employee.marritialStatus} setHook={employee.setMarritialStatus} data={marritialStatuses} placeholder={'Estado civil'}/>
                            </div>
                        </div>
                    }

                    { step == 3 &&
                        <div>
                            <div className='grid-2'>
                                <InputBox hook={employee.phone} setHook={employee.setPhone} placeholder={'Teléfono'}/>
                                <InputBox hook={employee.email} setHook={employee.setEmail} placeholder={'Email'}/>  
                            </div>
                        </div>
                    }

                    { step == 4 &&
                        <div>
                            <div className='grid-4'>
                                <InputBox hook={employee.street} setHook={employee.setStreet} placeholder={'Calle'}/>
                                <InputBox hook={employee.interiorNumber} setHook={employee.setInteriorNumber} placeholder={'Número interior'}/>
                                <InputBox hook={employee.exteriorNumber} setHook={employee.setExteriorNumber} placeholder={'Número exterior'}/>  
                                <InputBox hook={employee.crossings} setHook={employee.setCrossings} placeholder={'Cruzamientos'}/>  
                            </div>

                            <div className='grid-2'>
                                <InputBox hook={employee.colony} setHook={employee.setColony} placeholder={'Colonia'}/>
                                <InputBox hook={employee.code} setHook={employee.setCode} placeholder={'Código postal'}/>
                                <InputBox hook={employee.state} setHook={employee.setState} placeholder={'Estado'}/>  
                                <InputBox hook={employee.city} setHook={employee.setCity} placeholder={'Municipio'}/>  
                            </div>

                            <div className='grid-1'>
                                <TextAreaBox placeholder={'Referencias'} hook={employee.descriptionAddress} setHook={employee.setDescriptionAddress}/>
                            </div>
                        </div>
                    }

                    { step == 5 &&
                        <div>
                            <div className='grid-3'>
                                <SelectBox hook={employee.department} setHook={employee.setDepartment} data={employee.departments} placeholder={'Departamentos'}/>
                                <InputBox hook={employee.position} setHook={employee.setPosition} placeholder={'Posición'}/>
                                <InputBox hook={employee.shift} setHook={employee.setShift} placeholder={'Turno'}/>  
                            </div>

                            <div className='grid-2'>
                                <SelectBox hook={employee.businessName} setHook={employee.setBusinessName} data={employee.businessNames} placeholder={'Razón social'}/>  
                                <InputBox hook={employee.salary} setHook={employee.setSalary} placeholder={'Salario'} type='number'/>  
                            </div>

                            <div className='grid-1'>
                                <TextAreaBox hook={employee.descriptionPosition} setHook={employee.setDescriptionPosition} placeholder={'Descripción'}/>  
                            </div>
                        </div>
                    }

                    { step == 6 &&
                        <div>
                            <div className='grid-2'>
                                <InputBox hook={employee.productivityBonus} setHook={employee.setProductivityBonus} placeholder={'Bono de productividad'} type='number' icon={iconPercent}/>
                                <InputBox hook={employee.mealSubsidy} setHook={employee.setMealSubsidy} placeholder={'Subsidio de alimentos'} type='number' icon={iconPercent}/>
                                <InputToggle hook={employee.hasInfonavit} setHook={employee.setHasInfonavit} placeholder={'Infonavit'} height={30}/> 
                                <InputToggle hook={employee.hasFonacot} setHook={employee.setHasFonacot} placeholder={'Fonacot'} height={30}/> 
                            </div>
                        </div>
                    }

                    { step == 7 &&
                        <div>
                            <div className='grid-3'>
                                <InputToggle hook={employee.hasBonus1} setHook={employee.setHasbonus1} placeholder={'VD'} height={30}/> 
                                <InputToggle hook={employee.hasBonus2} setHook={employee.setHasbonus2} placeholder={'PA'} height={30}/> 
                                <InputToggle hook={employee.hasBonus3} setHook={employee.setHasbonus3} placeholder={'BT'} height={30}/> 
                            </div>
                        </div>
                    }

                    { step == 8 &&
                        <div>
                            <div className='grid-2'>
                                <InputBox hook={employee.beneficiarieFirstName} setHook={employee.setBeneficiarieFirstName} placeholder={'Nombre'}/>  
                                <InputBox hook={employee.beneficiarieMiddleName} setHook={employee.setBeneficiarieMiddleName} placeholder={'Segundo Nombre'}/> 
                                <InputBox hook={employee.beneficiarieLastName} setHook={employee.setBeneficiarieLastName} placeholder={'Apellido Paterno'}/> 
                                <InputBox hook={employee.beneficiarieSurname} setHook={employee.setBeneficiarieSurname} placeholder={'Apellido Materno'}/>
                                <InputBox hook={employee.beneficiariePhone} setHook={employee.setBeneficiariePhone} placeholder={'Teléfono'}/>  
                                <InputBox hook={employee.beneficiarieEmail} setHook={employee.setBeneficiarieEmail} placeholder={'Email'}/>
                            </div>

                            <div className='grid-1'>
                                <InputBox hook={employee.beneficiarieRelationship} setHook={employee.setBeneficiarieRelationship} placeholder={'Parentezco'}/> 
                            </div>
                        </div>
                    }

                    { step == 9 &&
                        <div>
                            <div className='grid-1'>
                                <InputBox hook={employee.shirtSize} setHook={employee.setShirtSize} placeholder={'Playera'}/>  
                            </div>
                        </div>
                    }

            </LayoutSteps>
        </div>
    )
}
