import React from 'react'
import { LayoutNavbar } from '../components/layout/LayoutNavbar'
import { PageAddEmployee } from '../components/pages/PageAddEmployee'

export const ViewAddEmployee = ({ role }) => {
    return (
        <main>
            <LayoutNavbar permissions={role}/>
            <PageAddEmployee/>
        </main>
    )
}
