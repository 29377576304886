import React from 'react'
import { iconUser } from '../../utils/routes/assets'
import { SwalImage } from '../../utils/functions.js/swal';

export const Image = ({ image = iconUser }) => {
      
    return (
        <div className='ui-image' onClick={(e) => SwalImage(image)}>
            <img src={image ? image : iconUser} />
        </div>
    )
}
