import React, { useState } from 'react'
import { iconUser } from '../../utils/routes/assets';
import { SwalImage } from '../../utils/functions.js/swal';

export const InputImage = ({ hook, setHook, blocked=false }) => {

    const [imagePreviewUrl, setImagePreviewUrl] = useState('');

    const handleImageChange = (e) => {
        e.preventDefault();

        if (e.target.files && e.target.files[0]) { setHook(e.target.files[0]); }
    
        let reader = new FileReader();
        let file = e.target.files[0];
    
        if (file) {
          reader.onloadend = () => { setImagePreviewUrl(reader.result); };
          reader.readAsDataURL(file);
        }
    };

    function clicked(id){
        if(!blocked){document.getElementById(id).click();}
        if(blocked){SwalImage(imagePreviewUrl || hook || iconUser)}
    }

    return (
      <div className={blocked ? 'input-image input-image-blocked' : 'input-image'}>
          <input type="file" onChange={handleImageChange} id='input-image' />
          <img onClick={() => clicked('input-image')}  src={imagePreviewUrl || hook || iconUser}/>
      </div>
    )
}
